import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <a id="planes"></a><h2>Planes</h2>
    <a id="what-is-a-plane"></a><h5>WHAT IS A PLANE?</h5>
    <p className="initial">The planes of existence are different
realities with interwoven connections. Except for rare linking points,
each plane is effectively its own universe with its own natural laws. </p>
    <p>The planes break down into a number of general types: the
Material Plane, the Transitive Planes, the Inner Planes, the Outer
Planes, and the demiplanes.</p>
    <a id="what-is-a-material-plane"></a><p><b>Material Plane</b>: The Material Plane tends to be the most
Earthlike of all planes and operates under the same set of natural laws
that our own real world does. This is the default plane for most
adventures.</p>
    <a id="what-are-transitive-planes"></a><p><b>Transitive Planes</b>: These three planes have one
important common characteristic: Each is used to get from one place to
another. The Astral Plane is a conduit to all other planes, while the
Ethereal Plane and the Plane of Shadow both serve as means of
transportation within the Material Plane they&#8217;re connected to. These
planes have the strongest regular interaction with the Material Plane
and are often accessed by using various spells. They have native
inhabitants as well.</p>
    <a id="waht-are-inner-planes"></a><p><b>Inner Planes</b>: These six planes are manifestations of
the basic building blocks of the universe. Each is made up of a single
type of energy or element that overwhelms all others. The natives of a
particular Inner Plane are made of the same energy or element as the
plane itself.</p>
    <a id="what-are-outer-planes"></a><p><b>Outer Planes</b>: The deities live on the Outer Planes, as
do creatures such as celestials, demons, and devils. Each of the Outer
Planes has an alignment, representing a particular moral or ethical
outlook, and the natives of each plane tend to behave in agreement with
that plane&#8217;s alignment. The Outer Planes are also the final resting
place of souls from the Material Plane, whether that final rest takes
the form of calm introspection or eternal damnation.</p>
    <a id="what-are-demiplanes"></a><p><b>Demiplanes</b>: This catch-all category covers all
extradimensional spaces that function like planes but have measurable
size and limited access. Other kinds of planes are theoretically
infinite in size, but a demiplane might be only a few hundred feet
across.</p>
    <a id="planar-traits"></a><h5>PLANAR TRAITS</h5>
    <p className="initial">Each plane of existence has its own
properties&#8212;the natural laws of its universe.</p>
    <p>Planar traits are broken down into a number of general areas.
All planes have the following kinds of traits.</p>
    <p><b>Physical Traits</b>: These traits determine the laws of
physics and nature on the plane, including how gravity and time
function.</p>
    <p><b>Elemental and Energy Traits</b>: These traits determine the
dominance of particular elemental or energy forces.</p>
    <p><b>Alignment Traits</b>: Just as characters may be lawful
neutral or chaotic good, many planes are tied to a particular moral or
ethical outlook.</p>
    <p><b>Magic Traits</b>: Magic works differently from plane to
plane, and magic traits set the boundaries for what it can and can&#8217;t do.</p>
    <a id="physical-traits"></a><h6>Physical Traits</h6>
    <p className="initial">The two most important natural laws set by
physical traits are how gravity works and how time passes. Other
physical traits pertain to the size and shape of a plane and how easily
a plane&#8217;s nature can be altered.</p>
    <a id="gravity"></a><p><b>Gravity</b>: The direction of gravity&#8217;s pull may be
unusual, and it might even change directions within the plane itself.</p>
    <a id="normal-gravity"></a><p><i>Normal Gravity</i>: Most planes have gravity similar to
that of the Material Plane. The usual rules for ability scores,
carrying capacity, and encumbrance apply. Unless otherwise noted in a
description, it is assumed every plane has the normal gravity trait.</p>
    <a id="heavy-gravity"></a><p><i>Heavy Gravity</i>: The gravity on a plane with this trait
is much more intense than on the Material Plane. As a result, <a href="skillsAll.html#balance" style={{
        "color": "rgb(87, 158, 182)"
      }}>Balance</a>,
      <a href="skillsAll.html#climb" style={{
        "color": "rgb(87, 158, 182)"
      }}>Climb</a>,
      <a href="skillsAll.html#jump" style={{
        "color": "rgb(87, 158, 182)"
      }}>Jump</a>,
      <a href="skillsAll.html#ride" style={{
        "color": "rgb(87, 158, 182)"
      }}>Ride</a>,
      <a href="skillsAll.html#swim" style={{
        "color": "rgb(87, 158, 182)"
      }}>Swim</a>,
and <a href="skillsAll.html#tumble" style={{
        "color": "rgb(87, 158, 182)"
      }}>Tumble</a>
checks incur a &#8211;2 circumstance penalty, as do all attack
rolls. All item weights are effectively doubled, which might affect a
character&#8217;s speed. Weapon ranges are halved. A character&#8217;s Strength and
Dexterity scores are not affected.</p>
    <p>Characters who fall on a heavy gravity plane take 1d10 points
of damage for each 10 feet fallen, to a maximum of 20d10 points of
damage.</p>
    <a id="light.gravity"></a><p><i>Light Gravity</i>: The gravity on a plane with this trait
is less intense than on the Material Plane. As a result, creatures find
that they can lift more, but their movements tend to be ungainly.
Characters on a plane with the light gravity trait take a &#8211;2
circumstance penalty on attack rolls and Balance, Ride, Swim, and
Tumble checks. All items weigh half as much. Weapon ranges double, and
characters gain a +2 circumstance bonus on Climb and Jump checks.
Strength and Dexterity don&#8217;t change as a result of light gravity, but
what you can do with such scores does change. These advantages apply to
travelers from other planes as well as natives.</p>
    <p>Falling characters on a light gravity plane take 1d4 points of
damage for each 10 feet of the fall (maximum 20d4).</p>
    <a id="no-gravity"></a><p><i>No Gravity</i>: Individuals on a plane with this trait
merely float in space, unless other resources are available to provide
a direction for gravity&#8217;s pull.</p>
    <a id="objective-directional-gravity"></a><p><i>Objective Directional Gravity</i>: The strength of gravity
on a plane with this trait is the same as on the Material Plane, but
the direction is not the traditional &#8220;down&#8221; toward the ground. It may
be down toward any solid object, at an angle to the surface of the
plane itself, or even upward.</p>
    <p>In addition, objective directional gravity may change from
place to place. The direction of &#8220;down&#8221; may vary.</p>
    <a id="subjective-directional-gravity"></a><p><i>Subjective Directional Gravity</i>: The strength of gravity
on a plane with this trait is the same as on the Material Plane, but
each individual chooses the direction of gravity&#8217;s pull. Such a plane
has no gravity for unattended objects and nonsentient creatures. This
sort of environment can be very disorienting to the newcomer, but is
common on &#8220;weightless&#8221; planes.</p>
    <p>Characters on a plane with subjective directional gravity can
move normally along a solid surface by imagining &#8220;down&#8221; near their
feet. If suspended in midair, a character &#8220;flies&#8221; by merely choosing a
&#8220;down&#8221; direction and &#8220;falling&#8221; that way. Under such a procedure, an
individual &#8220;falls&#8221; 150 feet in the first round and 300 feet in each
succeeding round. Movement is straight-line only. In order to stop, one
has to slow one&#8217;s movement by changing the designated &#8220;down&#8221; direction
(again, moving 150 feet in the new direction in the first round and 300
feet per round thereafter).</p>
    <p>It takes a DC 16 Wisdom check to set a new direction of
gravity as a free action; this check can be made once per round. Any
character who fails this Wisdom check in successive rounds receives a
+6 bonus on subsequent checks until he or she succeeds.</p>
    <a id="time"></a><p><b>Time</b>: The rate of time&#8217;s passage can vary on different
planes, though it remains constant within any particular plane. Time is
always subjective for the viewer. The same subjectivity applies to
various planes. Travelers may discover that they&#8217;ll pick up or lose
time while moving among the planes, but from their point of view, time
always passes naturally.</p>
    <a id="normal-time"></a><p><i>Normal Time</i>: This trait describes the way time passes
on the Material Plane. One hour on a plane with normal time equals one
hour on the Material Plane. Unless otherwise noted in a description,
every plane has the normal time trait.</p>
    <a id="timeless"></a><p><i>Timeless</i>: On planes with this trait, time still passes,
but the effects of time are diminished. How the timeless trait can
affect certain activities or conditions such as hunger, thirst, aging,
the effects of poison, and healing varies from plane to plane.</p>
    <p>The danger of a timeless plane is that once one leaves such a
plane for one where time flows normally, conditions such as hunger and
aging do occur retroactively. </p>
    <a id="flowing-time"></a><p><i>Flowing Time</i>: On some planes, time can flow faster or
slower. One may travel to another plane, spend a year there, then
return to the Material Plane to find that only six seconds have
elapsed. Everything on the plane returned to is only a few seconds
older. But for that traveler and the items, spells, and effects working
on him, that year away was entirely real.</p>
    <p>When designating how time works on planes with flowing time,
put the Material Plane&#8217;s flow of time first, followed by the same flow
in the other plane. </p>
    <a id="erratic-time"></a><p><i>Erratic Time</i>: Some planes have time that slows down and
speeds up, so an individual may lose or gain time as he moves between
the two planes. The following is provided as an example.</p>
    <a id="table-erratic-time"></a><table border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "400px"
    }}>
      <tbody>
        <tr valign="bottom">
          <th>d%</th>
          <th>Time on
Material Plane</th>
          <th>Time on
Erratic Time Plane</th>
        </tr>
        <tr className="odd-row">
          <td>01&#8211;10</td>
          <td>1 day</td>
          <td>1 round</td>
        </tr>
        <tr>
          <td>11&#8211;40</td>
          <td>1 day</td>
          <td>1 hour</td>
        </tr>
        <tr className="odd-row">
          <td>41&#8211;60</td>
          <td>1 day</td>
          <td>1 day</td>
        </tr>
        <tr>
          <td>61&#8211;90</td>
          <td>1 hour</td>
          <td>1 day</td>
        </tr>
        <tr className="odd-row">
          <td>91&#8211;100</td>
          <td>1 round</td>
          <td>1 day</td>
        </tr>
      </tbody>
    </table>
    <p>To the denizens of such a plane, time flows naturally and the
shift is unnoticed.</p>
    <p>If a plane is timeless with respect to magic, any spell cast
with a noninstantaneous duration is permanent until dispelled.</p>
    <a id="shape-and-size"></a><p><b>Shape and Size</b>: Planes come in a variety of sizes and
shapes. Most planes are infinite, or at least so large that they may as
well be infinite.</p>
    <a id="infinite"></a><p><i>Infinite</i>: Planes with this trait go on forever, though
they may have finite components within them. Or they may consist of
ongoing expanses in two directions, like a map that stretches out
infinitely.</p>
    <a id="finite-shape"></a><p><i>Finite Shape</i>: A plane with this trait has defined edges
or borders. These borders may adjoin other planes or hard, finite
borders such as the edge of the world or a great wall. Demiplanes are
often finite.</p>
    <a id="self-contained-shape"></a><p><i>Self-Contained Shape</i>: On planes with this trait, the
borders wrap in on themselves, depositing the traveler on the other
side of the map. A spherical plane is an example of a self-contained,
finite plane, but there can be cubes, toruses, and flat planes with
magical edges that teleport the traveler to an opposite edge when he
crosses them. </p>
    <p>Some demiplanes are self-contained.</p>
    <a id="morphic-traits"></a><p><b>Morphic Traits</b>: This trait measures how easily the
basic nature of a plane can be changed. Some planes are responsive to
sentient thought, while others can be manipulated only by extremely
powerful creatures. And some planes respond to physical or magical
efforts.</p>
    <a id="alterable-morphic"></a><p><i>Alterable Morphic</i>: On a plane with this trait, objects
remain where they are (and what they are) unless affected by physical
force or magic. You can change the immediate environment as a result of
tangible effort. </p>
    <a id="highly-morphic"></a><p><i>Highly Morphic</i>: On a plane with this trait, features of
the plane change so frequently that it&#8217;s difficult to keep a particular
area stable. Such planes may react dramatically to specific spells,
sentient thought, or the force of will. Others change for no reason. </p>
    <a id="magically-morphic"></a><p><i>Magically Morphic</i>: Specific spells can alter the basic
material of a plane with this trait.</p>
    <a id="divinely-morphic"></a><p><i>Divinely Morphic</i>: Specific unique beings (deities or
similar great powers) have the ability to alter objects, creatures, and
the landscape on planes with this trait. Ordinary characters find these
planes similar to alterable planes in that they may be affected by
spells and physical effort. But the deities may cause these areas to
change instantly and dramatically, creating great kingdoms for
themselves. </p>
    <a id="static"></a><p><i>Static</i>: These planes are unchanging. Visitors cannot
affect living residents of the plane, nor objects that the denizens
possess. Any spells that would affect those on the plane have no effect
unless the plane&#8217;s static trait is somehow removed or suppressed.
Spells cast before entering a plane with the static trait remain in
effect, however.</p>
    <p>Even moving an unattended object within a static plane
requires a DC 16 Strength check. Particularly heavy objects may be
impossible to move.</p>
    <a id="sentient"></a><p><i>Sentient</i>: These planes are ones that respond to a
single thought&#8212; that of the plane itself. Travelers would find the
plane&#8217;s landscape changing as a result of what the plane thought of the
travelers, either becoming more or less hospitable depending on its
reaction.</p>
    <a id="elemental-and-energy-traits"></a><h6>Elemental and Energy Traits</h6>
    <p className="initial">Four basic elements and two types of energy
together make up everything. The elements are earth, air, fire, and
water. The types of energy are positive and negative.</p>
    <p>The Material Plane reflects a balancing of those elements and
energies; all are found there. Each of the Inner Planes is dominated by
one element or type of energy. Other planes may show off various
aspects of these elemental traits. Many planes have no elemental or
energy traits; these traits are noted in a plane&#8217;s description only
when they are present.</p>
    <a id="air-dominant"></a><p><b>Air-Dominant</b>: Mostly open space, planes with this trait
have just a few bits of floating stone or other elements. They usually
have a breathable atmosphere, though such a plane may include clouds of
acidic or toxic gas. Creatures of the earth subtype are uncomfortable
on air-dominant planes because they have little or no natural earth to
connect with. They take no actual damage, however.</p>
    <a id="earth-dominant"></a><p><b>Earth-Dominant</b>: Planes with this trait are mostly
solid. Travelers who arrive run the risk of suffocation if they don&#8217;t
reach a cavern or other pocket within the earth. Worse yet, individuals
without the ability to burrow are entombed in the earth and must dig
their way out (5 feet per turn). Creatures of the air subtype are
uncomfortable on earth dominant planes because these planes are tight
and claustrophobic to them. But they suffer no inconvenience beyond
having difficulty moving.</p>
    <a id="fire-dominant"></a><p><b>Fire-Dominant</b>: Planes with this trait are composed of
flames that continually burn without consuming their fuel source.
Fire-dominant planes are extremely hostile to Material Plane creatures,
and those without resistance or immunity to fire are soon immolated.
Unprotected wood, paper, cloth, and other flammable materials catch
fire almost immediately, and those wearing unprotected flammable
clothing catch on fire. In addition, individuals take 3d10 points of
fire damage every round they are on a fire-dominant plane. Creatures of
the water subtype are extremely uncomfortable on fire-dominant planes.
Those that are made of water take double damage each round.</p>
    <a id="water-dominant"></a><p><b>Water-Dominant</b>: Planes with this trait are mostly
liquid. Visitors who can&#8217;t breathe water or reach a pocket of air will
likely drown. Creatures of the fire subtype are extremely uncomfortable
on water-dominant planes. Those made of fire take 1d10 points of damage
each round.</p>
    <a id="positive-dominant"></a><p><b>Positive-Dominant</b>: An abundance of life characterizes
planes with this trait. The two kinds of positive-dominant traits are
minor positive-dominant and major positive-dominant.</p>
    <p>A minor positive-dominant plane is a riotous explosion of life
in all its forms. Colors are brighter, fires are hotter, noises are
louder, and sensations are more intense as a result of the positive
energy swirling through the plane. All individuals in a
positive-dominant plane gain fast healing 2 as an extraordinary ability.</p>
    <p>Major positive-dominant planes go even further. A creature on
a major positive-dominant plane must make a DC 15 Fortitude save to
avoid being <a href="abilitiesAndConditions.html#blinded" style={{
        "color": "rgb(87, 158, 182)"
      }}>blinded</a> for 10 rounds by the
brilliance of the
surroundings. Simply being on the plane grants fast healing 5 as an
extraordinary ability. In addition, those at full hit points gain 5
additional temporary hit points per round. These temporary hit points
fade 1d20 rounds after the creature leaves the major positive- dominant
plane. However, a creature must make a DC 20 Fortitude save each round
that its temporary hit points exceed its normal hit point total.
Failing the saving throw results in the creature exploding in a riot of
energy, killing it.</p>
    <a id="negative-dominant"></a><p><b>Negative-Dominant</b>: Planes with this trait are vast,
empty reaches that suck the life out of travelers who cross them. They
tend to be lonely, haunted planes, drained of color and filled with
winds bearing the soft moans of those who died within them. As with
positive-dominant planes, negative-dominant planes can be either minor
or major. On minor negative-dominant planes, living creatures take 1d6
points of damage per round. At 0 hit points or lower, they crumble into
ash.</p>
    <p>Major negative-dominant planes are even more severe. Each
round, those within must make a DC 25 Fortitude save or gain a negative
level. A creature whose negative levels equal its current levels or Hit
Dice is slain, becoming a wraith.</p>
    <p>The death ward spell protects a traveler from the damage and <a href="abilitiesAndConditions.html#enegy-drained" style={{
        "color": "rgb(87, 158, 182)"
      }}>energy drain</a> of a
negative-dominant plane.</p>
    <h6><a id="alignment-traits"></a>Alignment Traits</h6>
    <p className="initial">Some planes have a predisposition to a certain
alignment. Most of the inhabitants of these planes also have the
plane&#8217;s particular alignment, even powerful creatures such as deities.
In addition, creatures of alignments contrary to the plane have a
tougher time dealing with its natives and situations.</p>
    <p>The alignment trait of a plane affects social interactions
there. Characters who follow other alignments than most of the
inhabitants do may find life more difficult.</p>
    <p>Alignment traits have multiple components. First are the moral
(good or evil) and ethical (lawful or chaotic) components; a plane can
have either a moral component, an ethical component, or one of each.
Second, the specific alignment trait indicates whether each moral or
ethical component is mildly or strongly evident.</p>
    <a id="good-aligned-evil-aligned"></a><p><b>Good-Aligned/Evil-Aligned</b>: These planes have chosen a
side in the battle of good versus evil. No plane can be both
good-aligned and evil-aligned.</p>
    <a id="law-aligned-chaos-aligned"></a><p><b>Law-Aligned/Chaos-Aligned</b>: Law versus chaos is the key
struggle for these planes and their residents. No plane can be both
law-aligned and chaos-aligned.</p>
    <p>Each part of the moral/ethical alignment trait has a
descriptor, either &#8220;mildly&#8221; or &#8220;strongly,&#8221; to show how powerful the
influence of alignment is on the plane.</p>
    <a id="mildly-aligned"></a><p><b>Mildly Aligned</b>: Creatures who have an alignment
opposite that of a mildly aligned plane take a &#8211;2 circumstance penalty
on all Charisma-based checks.</p>
    <a id="strongly-aligned"></a><p><b>Strongly Aligned</b>: On planes that are strongly aligned,
a &#8211;2 circumstance penalty applies on all Charisma-based checks made by
all creatures not of the plane&#8217;s alignment. In addition, the &#8211;2 penalty
affects all Intelligence-based and Wisdom-based checks, too.</p>
    <p>The penalties for the moral and ethical components of the
alignment trait do stack.</p>
    <a id="neutral-aligned"></a><p><b>Neutral-Aligned</b>: A mildly neutral-aligned plane does
not apply a circumstance penalty to anyone.</p>
    <p>The Material Plane is considered mildly neutral-aligned,
though it may contain high concentrations of evil or good, law or chaos
in places.</p>
    <p>A strongly neutral-aligned plane would stand in opposition to
all other moral and ethical principles: good, evil, law, and chaos.
Such a plane may be more concerned with the balance of the alignments
than with accommodating and accepting alternate points of view. In the
same fashion as for other strongly aligned planes, strongly
neutral-aligned planes apply a &#8211;2 circumstance penalty to
Intelligence-, Wisdom-, or Charisma-based checks by any creature that
isn&#8217;t neutral. The penalty is applied twice (once for law/chaos, and
once for good/evil), so neutral good, neutral evil, lawful neutral, and
chaotic neutral creatures take a &#8211;2 penalty and lawful good, chaotic
good, chaotic evil, and lawful evil creatures take a &#8211;4 penalty.</p>
    <a id="magic-traits"></a><h6>Magic Traits</h6>
    <p className="initial">A plane&#8217;s magic trait describes how magic
works on the plane compared to how it works on the Material Plane.
Particular locations on a plane (such as those under the direct control
of deities) may be pockets where a different magic trait applies.</p>
    <a id="normal-magic"></a><p><b>Normal Magic</b>: This magic trait means that all spells
and supernatural abilities function as written. Unless otherwise noted
in a description, every plane has the normal magic trait.</p>
    <a id="wild-magic"></a><p><b>Wild Magic</b>: On a plane with the wild magic trait spells
and spell-like abilities function in radically different and sometimes
dangerous ways. Any spell or spell-like ability used on a wild magic
plane has a chance to go awry. The caster must make a level check (DC
15 + the level of the spell or effect) for the magic to function
normally. For spell-like abilities, use the level or HD of the creature
employing the ability for the caster level check and the level of the
spell-like ability to set the DC for the caster level check.</p>
    <p>Failure on this check means that something strange happens;
roll d% and consult the following table.</p>
    <a id="table-wild-magic-effects"></a><table border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <th style={{
            "width": "7%"
          }}>d%</th>
          <th>Effect</th>
        </tr>
        <tr className="odd-row">
          <td>01&#8211;19</td>
          <td>Spell rebounds on caster with normal effect. If the
spell cannot affect the caster, it simply fails.</td>
        </tr>
        <tr>
          <td>20&#8211;23</td>
          <td>A circular pit 15 feet wide opens under the caster&#8217;s
feet; it is 10 feet deep per level of the caster.</td>
        </tr>
        <tr className="odd-row">
          <td>24&#8211;27</td>
          <td>The spell fails, but the target or targets of the spell
are pelted with a rain of small objects (anything from flowers to
rotten fruit), which disappear upon striking. The barrage continues for
1 round. During this time the targets are <a href="abilitiesAndConditions.html#blinded" style={{
              "color": "rgb(87, 158, 182)"
            }}>blinded</a> and must make <a href="skillsAll.html#concentration" style={{
              "color": "rgb(87, 158, 182)"
            }}>Concentration</a>
checks (DC 15 +
spell level) to cast spells.</td>
        </tr>
        <tr>
          <td>28&#8211;31</td>
          <td>The spell affects a random target or area. Randomly
choose a different target from among those in range of the spell or
center the spell at a random place within range of the spell. To
generate direction randomly, roll 1d8 and count clockwise around the
compass, starting with south. To generate range randomly, roll 3d6.
Multiply the result by 5 feet for close range spells, 20 feet for
medium range spells, or 80 feet for long range spells.</td>
        </tr>
        <tr className="odd-row">
          <td>32&#8211;35</td>
          <td>The spell functions normally, but any material
components are not consumed. The spell is not expended from the
caster&#8217;s mind (a spell slot or prepared spell can be used again). An
item does not lose charges, and the effect does not count against an
item&#8217;s or spell-like ability&#8217;s use limit. </td>
        </tr>
        <tr>
          <td>36&#8211;39</td>
          <td>The spell does not function. Instead, everyone (friend
or foe) within 30 feet of the caster receives the effect of a heal
spell.</td>
        </tr>
        <tr className="odd-row">
          <td>40&#8211;43</td>
          <td>The spell does not function. Instead, a deeper darkness
and a silence effect cover a 30-foot radius around the caster for 2d4
rounds.</td>
        </tr>
        <tr>
          <td>44&#8211;47</td>
          <td>The spell does not function. Instead, a reverse gravity
effect covers a 30-foot radius around the caster for 1 round. </td>
        </tr>
        <tr className="odd-row">
          <td>48&#8211;51</td>
          <td>The spell functions, but shimmering colors swirl around
the caster for 1d4 rounds. Treat this a glitterdust effect with a save
DC of 10 + the level of the spell that generated this result.</td>
        </tr>
        <tr>
          <td>52&#8211;59</td>
          <td>Nothing happens. The spell does not function. Any
material components are used up. The spell or spell slot is used up,
and charges or uses from an item are used up.</td>
        </tr>
        <tr className="odd-row">
          <td>60&#8211;71</td>
          <td>Nothing happens. The spell does not function. Any
material components are not consumed. The spell is not expended from
the caster&#8217;s mind (a spell slot or prepared spell can be used again).
An item does not lose charges, and the effect does not count against an
item&#8217;s or spell-like ability&#8217;s use limit.</td>
        </tr>
        <tr>
          <td>72&#8211;98</td>
          <td>The spell functions normally.</td>
        </tr>
        <tr className="odd-row">
          <td>99&#8211;100</td>
          <td>The spell functions strongly. Saving throws against the
spell incur a &#8211;2 penalty. The spell has the maximum possible effect, as
if it were cast with the <a href="featsAll.html#maximize-spell" style={{
              "color": "rgb(87, 158, 182)"
            }}>Maximize Spell</a> feat. If the
spell is already
maximized with the feat, there is no further effect. </td>
        </tr>
      </tbody>
    </table>
    <a id="impeded-magic"></a><p><b>Impeded Magic</b>: Particular spells and spell-like
abilities are more difficult to cast on planes with this trait, often
because the nature of the plane interferes with the spell.</p>
    <p>To cast an impeded spell, the caster must make a <a href="skillsAll.html#spellcraft" style={{
        "color": "rgb(87, 158, 182)"
      }}>Spellcraft</a>
check (DC 20 + the level of the spell). If the check fails, the spell
does not function but is still lost as a prepared spell or spell slot.
If the check succeeds, the spell functions normally.</p>
    <a id="enhanced-magic"></a><p><b>Enhanced Magic</b>: Particular spells and spell-like
abilities are easier to use or more powerful in effect on planes with
this trait than they are on the Material Plane.</p>
    <p>Natives of a plane with the enhanced magic trait are aware of
which spells and spell-like abilities are enhanced, but planar
travelers may have to discover this on their own.</p>
    <p>If a spell is enhanced, certain metamagic feats can be applied
to it without changing the spell slot required or the casting time.
Spellcasters on the plane are considered to have that feat or feats for
the purpose of applying them to that spell. Spellcasters native to the
plane must gain the feat or feats normally if they want to use them on
other planes as well.</p>
    <a id="limited-magic"></a><p><b>Limited Magic</b>: Planes with this trait permit only the
use of spells and spell-like abilities that meet particular
qualifications. Magic can be limited to effects from certain schools or
subschools, to effects with certain descriptors, or to effects of a
certain level (or any combination of these qualities). Spells and
spell-like abilities that don&#8217;t meet the qualifications simply don&#8217;t
work.</p>
    <a id="dead-magic"></a><p><b>Dead Magic</b>: These planes have no magic at all. A plane
with the dead magic trait functions in all respects like an antimagic
field spell. Divination spells cannot detect subjects within a dead
magic plane, nor can a spellcaster use teleport or another spell to
move in or out. The only exception to the &#8220;no magic&#8221; rule is permanent
planar portals, which still function normally.</p>
    <a id="how-planes-interact"></a><h5>HOW PLANES INTERACT</h5>
    <p className="initial"><b>Separate Planes</b>: Two planes that are
separate do not overlap or directly connect to each other. They are
like planets in different orbits. The only way to get from one separate
plane to the other is to go through a third plane.</p>
    <a id="coterminous-planes"></a><p><b>Coterminous Planes</b>: Planes that touch at specific
points are coterminous. Where they touch, a connection exists, and
travelers can leave one reality behind and enter the other.</p>
    <a id="coexistent-planes"></a><p><b>Coexistent Planes</b>: If a link between two planes can be
created at any point, the two planes are coexistent. These planes
overlap each other completely. A coexistent plane can be reached from
anywhere on the plane it overlaps. When moving on a coexistent plane,
it is often possible to see into or interact with the plane it coexists
with. </p>
    <a id="layered-planes"></a><h6>Layered Planes</h6>
    <p className="initial">Infinities may be broken into smaller
infinities, and planes into smaller, related planes. These layers are
effectively separate planes of existence, and each layer can have its
own planar traits. Layers are connected to each other through a variety
of planar gates, natural vortices, paths, and shifting borders.</p>
    <p>Access to a layered plane from elsewhere usually happens on a
specific layer: the first layer of the plane, which can be either the
top layer or the bottom layer, depending on the specific plane. Most
fixed access points (such as portals and natural vortices) reach this
layer, which makes it the gateway for other layers of the plane. The
plane shift spell also deposits the spellcaster on the first layer of
the plane.</p>
    <a id="plane-descriptions"></a><h3>PLANE DESCRIPTIONS</h3>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td></td>
        </tr>
      </tbody>
    </table>
    <h5><a id="material"></a><a id="the-material-plane"></a>THE MATERIAL PLANE</h5>
    <p className="initial">The Material Plane is the center of most
cosmologies and defines what is considered normal.</p>
    <p>The Material Plane has the following traits: </p>
    <ul>
      <li>
        <p>Normal gravity.</p>
      </li>
      <li>
        <p>Normal Time</p>
      </li>
      <li>
        <p>Alterable morphic.</p>
      </li>
      <li>
        <p>No Elemental or Energy Traits (specific locations may have
these traits, however)</p>
      </li>
      <li>
        <p>Mildly neutral-aligned.</p>
      </li>
      <li>
        <p>Normal magic. </p>
      </li>
    </ul>
    <a id="ethereal"></a><a id="the-ethereal-plane"></a><h5>THE ETHEREAL PLANE</h5>
    <p className="initial">The Ethereal Plane is coexistent with the
Material Plane and often other planes as well. The Material Plane
itself is visible from the Ethereal Plane, but it appears muted and
indistinct, its colors blurring into each other and its edges turning
fuzzy.</p>
    <p>While it is possible to see into the Material Plane from the
Ethereal Plane, the Ethereal Plane is usually <a href="abilitiesAndConditions.html#invisible" style={{
        "color": "rgb(87, 158, 182)"
      }}>invisible</a> to those on the
Material Plane. Normally, creatures on the Ethereal Plane cannot attack
creatures on the Material Plane, and vice versa. A traveler on the
Ethereal Plane is invisible, <a href="abilitiesAndConditions.html#incorporeal" style={{
        "color": "rgb(87, 158, 182)"
      }}>incorporeal</a>, and utterly silent
to someone
on the Material Plane. </p>
    <p>The Ethereal Plane is mostly empty of structures and
impediments. However, the plane has its own inhabitants. Some of these
are other ethereal travelers, but the ghosts found here pose a
particular peril to those who walk the fog. </p>
    <p>It has the following traits.</p>
    <ul>
      <li>
        <p>No gravity.</p>
      </li>
      <li>
        <p>Alterable morphic. The plane contains little to alter,
however.</p>
      </li>
      <li>
        <p>Mildly neutral-aligned.</p>
      </li>
      <li>
        <p>Normal magic. Spells function normally on the Ethereal
Plane, though they do not cross into the Material Plane. </p>
      </li>
    </ul>
    <p>The only exceptions are spells and spell-like abilities that
have the force descriptor and abjuration spells that affect ethereal
beings. Spellcasters on the Material Plane must have some way to detect
foes on the Ethereal Plane before targeting them with force-based
spells, of course. While it&#8217;s possible to hit ethereal enemies with a
force spell cast on the Material Plane, the reverse isn&#8217;t possible. No
magical attacks cross from the Ethereal Plane to the Material Plane,
including force attacks.</p>
    <a id="shadow"></a><a id="plane-of-shadow"></a><h5>PLANE OF SHADOW</h5>
    <p className="initial">The Plane of Shadow is a dimly lit dimension
that is both coterminous to and coexistent with the Material Plane. It
overlaps the Material Plane much as the Ethereal Plane does, so a
planar traveler can use the Plane of Shadow to cover great distances
quickly. The Plane of Shadow is also coterminous to other planes. With
the right spell, a character can use the Plane of Shadow to visit other
realities.</p>
    <p>The Plane of Shadow is a world of black and white; color
itself has been bleached from the environment. It is otherwise appears
similar to the Material Plane.</p>
    <p>Despite the lack of light sources, various plants, animals,
and humanoids call the Plane of Shadow home.</p>
    <p>The Plane of Shadow is magically morphic, and parts
continually flow onto other planes. As a result, creating a precise map
of the plane is next to impossible, despite the presence of landmarks.</p>
    <p>The Plane of Shadow has the following traits.</p>
    <ul>
      <li>
        <p>Magically morphic. Certain spells modify the base material
of the Plane of Shadow. The utility and power of these spells within
the Plane of Shadow make them particularly useful for explorers and
natives alike.</p>
      </li>
      <li>
        <p>Mildly neutral-aligned.</p>
      </li>
      <li>
        <p>Enhanced magic. Spells with the shadow descriptor are
enhanced on the Plane of Shadow. Such spells are cast as though they
were prepared with the <a href="featsAll.html#maximize-spell" style={{
            "color": "rgb(87, 158, 182)"
          }}>Maximize Spell</a> feat, though they
don&#8217;t require
the higher spell slots.</p>
      </li>
    </ul>
    <p>Furthermore, specific spells become more powerful on the Plane
of Shadow. Shadow conjuration and shadow evocation spells are 30% as
powerful as the conjurations and evocations they mimic (as opposed to
20%). Greater shadow conjuration and greater shadow evocation are 70%
as powerful (not 60%), and a shades spell conjures at 90% of the power
of the original (not 80%).</p>
    <ul>
      <li>
        <p>Impeded magic. Spells that use or generate light or fire
may fizzle when cast on the Plane of Shadow. A spellcaster attempting a
spell with the light or fire descriptor must succeed on a <a href="skillsAll.html#spellcraft" style={{
            "color": "rgb(87, 158, 182)"
          }}>Spellcraft</a>
check (DC 20 + the level of the spell). Spells that produce light are
less effective in general, because all light sources have their ranges
halved on the Plane of Shadow.</p>
      </li>
    </ul>
    <p>Despite the dark nature of the Plane of Shadow, spells that
produce, use, or manipulate darkness are unaffected by the plane.</p>
    <a id="astral"></a><a id="the-astral-plane"></a><h5>THE ASTRAL PLANE</h5>
    <p className="initial">The Astral Plane is the space between the
planes. When a character moves through an interplanar portal or
projects her spirit to a different plane of existence, she travels
through the Astral Plane. Even spells that allow instantaneous movement
across a plane briefly touch the Astral Plane.</p>
    <p>The Astral Plane is a great, endless sphere of clear silvery
sky, both above and below. Occasional bits of solid matter can be found
here, but most of the Astral Plane is an endless, open domain.</p>
    <p>Both planar travelers and refugees from other planes call the
Astral Plane home. </p>
    <p>The Astral Plane has the following traits.</p>
    <ul>
      <li>
        <p>Subjective directional gravity.</p>
      </li>
      <li>
        <p>Timeless. Age, hunger, thirst, poison, and natural healing
don&#8217;t function in the Astral Plane, though they resume functioning when
the traveler leaves the Astral Plane.</p>
      </li>
      <li>
        <p>Mildly neutral-aligned.</p>
      </li>
      <li>
        <p>Enhanced magic. All spells and spell-like abilities used
within the Astral Plane may be employed as if they were improved by the
          <a href="featsAll.html#quicken-spell" style={{
            "color": "rgb(87, 158, 182)"
          }}>Quicken Spell</a> feat. Already
quickened spells and spell-like abilities
are unaffected, as are spells from magic items. Spells so quickened are
still prepared and cast at their unmodified level. As with the Quicken
Spell feat, only one quickened spell can be cast per round.</p>
      </li>
    </ul>
    <a id="air"></a><a id="elemental-plane-of-air"></a><h5>ELEMENTAL PLANE OF AIR</h5>
    <p className="initial">The Elemental Plane of Air is an empty plane,
consisting of sky above and sky below.</p>
    <p>The Elemental Plane of Air is the most comfortable and
survivable of the Inner Planes, and it is the home of all manner of
airborne creatures. Indeed, flying creatures find themselves at a great
advantage on this plane. While travelers without flight can survive
easily here, they are at a disadvantage.</p>
    <p>The Elemental Plane of Air has the following traits.</p>
    <ul>
      <li>
        <p>Subjective directional gravity. Inhabitants of the plane
determine their own &#8220;down&#8221; direction. Objects not under the motive
force of others do not move.</p>
      </li>
      <li>
        <p>Air-dominant.</p>
      </li>
      <li>
        <p>Enhanced magic. Spells and spell-like abilities that use,
manipulate, or create air (including spells of the Air domain) are both
empowered and enlarged (as if the <a href="featsAll.html#empower-spell" style={{
            "color": "rgb(87, 158, 182)"
          }}>Empower Spell</a> and <a href="featsAll.html#enlarge-spell" style={{
            "color": "rgb(87, 158, 182)"
          }}>Enlarge
Spell</a>
metamagic feats had been used on them, but the spells don&#8217;t require
higher-level slots).</p>
      </li>
      <li>
        <p>Impeded magic. Spells and spell-like abilities that use or
create earth (including spells of the Earth domain and spells that
summon earth elementals or outsiders with the earth subtype) are
impeded.</p>
      </li>
    </ul>
    <a id="earth"></a><a id="elemental-plane-of-earth"></a><h5>ELEMENTAL PLANE OF EARTH</h5>
    <p className="initial">The Elemental Plane of Earth is a solid place
made of rock, soil, and stone. An unwary and unprepared traveler may
find himself entombed within this vast solidity of material and have
his life crushed into nothingness, his powdered remains a warning to
any foolish enough to follow.</p>
    <p>Despite its solid, unyielding nature, the Elemental Plane of
Earth is varied in its consistency, ranging from relatively soft soil
to veins of heavier and more valuable metal. </p>
    <p>The Elemental Plane of Earth has the following traits.</p>
    <ul>
      <li>
        <p>Earth-dominant.</p>
      </li>
      <li>
        <p>Enhanced magic. Spells and spell-like abilities that use,
manipulate, or create earth or stone (including those of the Earth
domain) are both empowered and extended (as if the <a href="featsAll.html#empower-spell" style={{
            "color": "rgb(87, 158, 182)"
          }}>Empower
Spell</a> and <a href="featsAll.html#extend-spell" style={{
            "color": "rgb(87, 158, 182)"
          }}>Extend Spell</a> metamagic feats had
been used on them, but the spells
don&#8217;t require higher-level slots). Spells and spell-like abilities that
are already empowered or extended are unaffected by this benefit.</p>
      </li>
      <li>
        <p>Impeded magic. Spells and spell-like abilities that use or
create air (including spells of the Air domain and spells that summon
air elementals or outsiders with the air subtype) are impeded.</p>
      </li>
    </ul>
    <a id="fire"></a><a id="elemental-plane-of-fire"></a><h5>ELEMENTAL PLANE OF FIRE</h5>
    <p className="initial">Everything is alight on the Elemental Plane of
Fire. The ground is nothing more than great, evershifting plates of
compressed flame. The air ripples with the heat of continual
firestorms, and the most common liquid is magma, not water. The oceans
are made of liquid flame, and the mountains ooze with molten lava.</p>
    <p>Fire survives here without need for fuel or air, but
flammables brought onto the plane are consumed readily. </p>
    <p>The Elemental Plane of Fire has the following traits.</p>
    <ul>
      <li>
        <p>Fire-dominant.</p>
      </li>
      <li>
        <p>Enhanced magic. Spells and spell-like abilities with the
fire descriptor are both maximized and enlarged (as if the <a href="featsAll.html#maximize-spell" style={{
            "color": "rgb(87, 158, 182)"
          }}>Maximize
Spell</a> and <a href="featsAll.html#enlarge-spell" style={{
            "color": "rgb(87, 158, 182)"
          }}>Enlarge Spell</a> had been used on
them, but the spells don&#8217;t
require higher-level slots). Spells and spell-like abilities that are
already maximized or enlarged are unaffected by this benefit.</p>
      </li>
      <li>
        <p>Impeded magic. Spells and spell-like abilities that use or
create water (including spells of the Water domain and spells that
summon water elementals or outsiders with the water subtype) are
impeded. </p>
      </li>
    </ul>
    <a id="water"></a><a id="elemental-plame-of-water"></a><h5>ELEMENTAL PLANE OF WATER</h5>
    <p className="initial">The Elemental Plane of Water is a sea without
a floor or a surface, an entirely fluid environment lit by a diffuse
glow. It is one of the more hospitable of the Inner Planes once a
traveler gets past the problem of breathing the local medium.</p>
    <p>The eternal oceans of this plane vary between ice cold and
boiling hot, between saline and fresh. They are perpetually in motion,
wracked by currents and tides. The plane&#8217;s permanent settlements form
around bits of flotsam and jetsam suspended within this endless liquid.
These settlements drift on the tides of the Elemental Plane of Water.</p>
    <p>The Elemental Plane of Water has the following traits.</p>
    <ul>
      <li>
        <p>Subjective directional gravity. The gravity here works
similar to that of the Elemental Plane of Air. But sinking or rising on
the Elemental Plane of Water is slower (and less dangerous) than on the
Elemental Plane of Air.</p>
      </li>
      <li>
        <p>Water-dominant.</p>
      </li>
      <li>
        <p>Enhanced magic. Spells and spell-like abilities that use
or create water are both extended and enlarged (as if the <a href="featsAll.html#extend-spell" style={{
            "color": "rgb(87, 158, 182)"
          }}>Extend
Spell</a>
and <a href="featsAll.html#enlarge-spell" style={{
            "color": "rgb(87, 158, 182)"
          }}>Enlarge Spell</a> metamagic feats
had been used on them, but the spells
don&#8217;t require higher-level slots). Spells and spell-like abilities that
are already extended or enlarged are unaffected by this benefit.</p>
      </li>
      <li>
        <p>Impeded magic. Spells and spell-like abilities with the
fire descriptor (including spells of the Fire domain) are impeded. </p>
      </li>
    </ul>
    <a id="negative-energy"></a><a id="negative-energy-plane"></a><h5>NEGATIVE ENERGY PLANE</h5>
    <p className="initial">To an observer, there&#8217;s little to see on the
Negative Energy Plane. It is a dark, empty place, an eternal pit where
a traveler can fall until the plane itself steals away all light and
life.</p>
    <p>The Negative Energy Plane is the most hostile of the Inner
Planes, and the most uncaring and intolerant of life. Only creatures
immune to its life-draining energies can survive there. </p>
    <p>The Negative Energy Plane has the following traits.</p>
    <ul>
      <li>
        <p>Subjective directional gravity.</p>
      </li>
      <li>
        <p>Major negative-dominant. Some areas within the plane have
only the minor negative-dominant trait, and these islands tend to be
inhabited.</p>
      </li>
      <li>
        <p>Enhanced magic. Spells and spell-like abilities that use
negative energy are maximized (as if the <a href="featsAll.html#maximize-spell" style={{
            "color": "rgb(87, 158, 182)"
          }}>Maximize
Spell</a> metamagic feat
had been used on them, but the spells don&#8217;t require higher-level
slots). Spells and spell-like abilities that are already maximized are
unaffected by this benefit. Class abilities that use negative energy,
such as rebuking and controlling undead, gain a +10 bonus on the roll
to determine Hit Dice affected. </p>
      </li>
      <li>
        <p>Impeded magic. Spells and spell-like abilities that use
positive energy, including cure spells, are impeded. Characters on this
plane take a &#8211;10 penalty on Fortitude saving throws made to remove
negative levels bestowed by an <a href="abilitiesAndConditions.html#energy-drained" style={{
            "color": "rgb(87, 158, 182)"
          }}>energy drain</a> attack. </p>
      </li>
    </ul>
    <p><b>Random Encounters</b>: Because the Negative Energy Plane is
virtually devoid of creatures, random encounters on the plane are
exceedingly rare.</p>
    <a id="positive-energy"></a><a id="positive-energy-plane"></a><h5>POSITIVE ENERGY PLANE</h5>
    <p className="initial">The Positive Energy Plane has no surface and
is akin to the Elemental Plane of Air with its wide-open nature.
However, every bit of this plane glows brightly with innate power. This
power is dangerous to mortal forms, which are not made to handle it.</p>
    <p>Despite the beneficial effects of the plane, it is one of the
most hostile of the Inner Planes. An unprotected character on this
plane swells with power as positive energy is force-fed into her. Then,
her mortal frame unable to contain that power, she immolates as if she
were a small planet caught at the edge of a supernova. Visits to the
Positive Energy Plane are brief, and even then travelers must be
heavily protected.</p>
    <p>The Positive Energy Plane has the following traits.</p>
    <ul>
      <li>
        <p>Subjective directional gravity.</p>
      </li>
      <li>
        <p>Major positive-dominant. Some regions of the plane have
the minor positive-dominant trait instead, and those islands tend to be
inhabited.</p>
      </li>
      <li>
        <p>Enhanced magic. Spells and spell-like abilities that use
positive energy, including cure spells, are maximized (as if the <a href="featsAll.html#maximize-spell" style={{
            "color": "rgb(87, 158, 182)"
          }}>Maximize
Spell</a> metamagic feat
had been used on them, but the spells
don&#8217;t require higher-level slots). Spells and spell-like abilities that
are already maximized are unaffected by this benefit. Class abilities
that use positive energy, such as turning and destroying undead, gain a
+10 bonus on the roll to determine Hit Dice affected. (Undead are
almost impossible to find on this plane, however.)</p>
      </li>
      <li>
        <p>Impeded magic. Spells and spell-like abilities that use
negative energy (including inflict spells) are impeded.</p>
      </li>
    </ul>
    <p><b>Random Encounters</b>: Because the Positive Energy Plane is
virtually devoid of creatures, random encounters on the plane are
exceedingly rare.</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      